import { localPoint } from "@visx/event";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { LegendOrdinal } from "@visx/legend";
import { AnimatedAxis } from "@visx/react-spring";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Line, BarStackHorizontal } from "@visx/shape";
import { SeriesPoint } from "@visx/shape/lib/types";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { format as d3Format } from "d3-format";
import { timeParse, timeFormat } from "d3-time-format";
import React from "react";
import { colorRange } from "../../src/helpers";
import { theme } from "../../themes/base";
import {
  filterKeyFromObjects,
  formatPercentage,
  formatNumber,
} from "../../utils";
import { compose } from "../../utils/compose";
import { ErrorWidget } from "../ErrorWidget";

type UnitType = "currency" | "percentage" | "number" | "simple";

type TooltipData = {
  bar: SeriesPoint<any> | any;
  color: string;
  height: number;
  index: number;
  key: string;
  width: number;
  x: number;
  y: number;
};

export type HorizontalBarsProps = {
  data: any;
  events?: boolean;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  numTicks?: number;
  unit?: UnitType;
  width: number;
  xUnit?: UnitType;
  yUnit?: UnitType;
};

const parseMonth = timeParse("%Y-%m");
const parseDate = timeParse("%Y-%m-%d");
const format = timeFormat("%x");

// @see https://observablehq.com/@camdecoster/d3-tester
const formatToSimpleCurrency = d3Format("$,.3~s");
const formatToSimpleNumber = d3Format(".3~s");

const formatDate = (date: string) => format(parseDate(date) as Date);
const formatMonth = (date: string) => format(parseMonth(date) as Date);
const formatSimpleCurrency = (value: number) => formatToSimpleCurrency(value);

// accessors
const getDate = (d: any) => d.date;
const getMonth = (d: any) => d.month;

export function HorizontalBars({
  data = [],
  height,
  margin = { top: 32, right: 40, bottom: 32, left: 40 },
  width,
  unit,
  xUnit,
  yUnit,
  numTicks,
}: HorizontalBarsProps) {
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: "#EEE",
    color: "#000",
  };

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom - 96;

  let tooltipTimeout: number;

  const filterX = (arr: any): any => filterKeyFromObjects("x", arr);
  const filterY = (arr: any): any => filterKeyFromObjects("y", arr);
  const filterY0 = (arr: any): any => filterKeyFromObjects("y0", arr);
  const filterY1 = (arr: any): any => filterKeyFromObjects("y1", arr);
  const filterX0 = (arr: any): any => filterKeyFromObjects("x0", arr);
  const filterX1 = (arr: any): any => filterKeyFromObjects("x1", arr);
  const filteredDataWithoutCoords = compose(
    filterX,
    filterX0,
    filterX1,
    filterY,
    filterY0,
    filterY1
  );
  const dataWithoutCoords = filteredDataWithoutCoords(data);

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const keys = Object.keys(dataWithoutCoords[0] || [])
    .filter((d) => d !== "date" && d !== "month")
    .sort((a, b) => parseInt(a) - parseInt(b)) as any[];

  const isMonthly = Object.keys(dataWithoutCoords[0] || []).includes("month");

  const values = dataWithoutCoords.reduce(
    (allTotals: any, currentDate: any) => {
      const totalCount = keys
        .sort((a, b) => a - b)
        .reduce((dailyTotal, k) => {
          if (currentDate[k]) {
            dailyTotal += Number(currentDate[k]);
          }
          return dailyTotal;
        }, 0);
      allTotals.push(totalCount);
      return allTotals;
    },
    [] as number[]
  );

  // scales
  const dateScale = scaleBand<string>({
    domain: dataWithoutCoords.map(getDate),
    paddingInner: 0.2,
    paddingOuter: 0.1,
    round: true,
  });

  const monthScale = scaleBand<string>({
    domain: dataWithoutCoords.map(getMonth),
    padding: 0,
    paddingInner: 0.2,
    paddingOuter: 0.1,
    round: true,
  });

  const valueScale = scaleLinear<number>({
    domain: [0, Math.max(...values)],
    nice: true,
  });

  const colorScale = scaleOrdinal<any, string>({
    domain: keys,
    range: colorRange,
  });

  dateScale.rangeRound([yMax, 0]);
  monthScale.rangeRound([yMax, 0]);
  valueScale.range([0, xMax]);

  if (!data || data.length < 1) {
    return <ErrorWidget h100 />;
  }

  if (width < 10) return null;

  return (
    <div
      style={{
        position: "relative",
        marginTop: "1.5rem",
        paddingLeft: "1rem",
      }}
    >
      <svg
        ref={containerRef}
        width={width}
        height={height}
        style={{ overflow: "visible" }}
      >
        <Group left={margin.left} top={margin.top}>
          <Grid
            xScale={valueScale}
            yScale={isMonthly ? monthScale : dateScale}
            width={xMax}
            height={yMax}
            stroke={theme.colors.primary}
            strokeOpacity={0.1}
          />
          <AnimatedAxis
            left={-24}
            hideAxisLine
            hideTicks
            numTicks={numTicks}
            orientation="left"
            scale={isMonthly ? monthScale : dateScale}
            stroke={theme.colors.primary}
            strokeWidth={1}
            tickFormat={isMonthly ? formatMonth : formatDate}
            tickLabelProps={() => ({
              fill: theme.colors.primary,
              fontSize: "clamp(0.5rem, 2vw, 0.6rem)",
              textAnchor: "middle",
            })}
          />

          <AnimatedAxis
            top={yMax}
            scale={valueScale}
            stroke={theme.colors.primary}
            numTicks={width < 16 * 32 ? 8 : 12}
            // @ts-ignore
            tickFormat={formatToSimpleNumber}
            tickStroke={theme.colors.primary}
            {...(unit === "currency" && { tickFormat: formatSimpleCurrency })}
            {...(xUnit === "currency" && { tickFormat: formatSimpleCurrency })}
            {...(xUnit === "percentage" && {
              tickFormat: formatPercentage.bind(null, 2),
            })}
            {...(yUnit === "currency" && {
              tickFormat: formatSimpleCurrency,
            })}
            {...(yUnit === "percentage" && {
              tickFormat: formatPercentage.bind(null, 2),
            })}
            tickLabelProps={() => ({
              fill: theme.colors.primary,
              fontSize: "clamp(0.5rem, 2vw, 0.6rem)",
            })}
            orientation="bottom"
          >
            {(props) => {
              const tickLabelSize = 10;
              const tickRotate = -45;
              const tickColor = theme.colors.primary;
              const axisCenter =
                (props.axisToPoint.x - props.axisFromPoint.x) / 2;
              return (
                <Group>
                  {props.ticks.map((tick, i) => {
                    const tickX = tick.to.x;
                    const tickY = tick.to.y + tickLabelSize;
                    return (
                      <Group
                        key={`vx-tick-${tick.value}-${i}`}
                        className={"vx-axis-tick"}
                      >
                        <Line
                          from={tick.from}
                          to={tick.to}
                          stroke={theme.colors.primary}
                        />
                        <Text
                          transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                          fontSize={tickLabelSize}
                          textAnchor="end"
                          fill={tickColor}
                        >
                          {tick.formattedValue}
                        </Text>
                      </Group>
                    );
                  })}
                  <Text
                    textAnchor="middle"
                    transform={`translate(${axisCenter}, 50)`}
                    fontSize="clamp(0.5rem, 2vw, 0.6rem)"
                  >
                    {props.label}
                  </Text>
                </Group>
              );
            }}
          </AnimatedAxis>

          <BarStackHorizontal
            data={data}
            keys={keys}
            y={isMonthly ? getMonth : getDate}
            xScale={valueScale}
            yScale={isMonthly ? monthScale : dateScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) => (
                <React.Fragment key={`bar-stacks-frag-${barStack.index}`}>
                  {barStack.bars.map((bar) => (
                    <Group
                      key={`bar-stack-frag-${barStack.index}-${bar.index}`}
                    >
                      <rect
                        key={`bar-stack-${barStack.index}-${bar.index}`}
                        x={bar.x}
                        y={bar.y}
                        width={bar.width >= 0 ? bar.width : 0}
                        height={bar.height}
                        fill={bar.color}
                        onMouseLeave={(event) => {
                          if (!window) return;
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                          event?.currentTarget?.style.setProperty(
                            "opacity",
                            "1"
                          );
                          event?.currentTarget?.style.setProperty(
                            "cursor",
                            "default"
                          );
                        }}
                        onMouseEnter={(event) => {
                          if (!window) return;
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                          event?.currentTarget.style.setProperty(
                            "opacity",
                            "1"
                          );
                          event?.currentTarget.style.setProperty(
                            "cursor",
                            "pointer"
                          );
                        }}
                        onMouseMove={(event) => {
                          if (!event) return;
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          // TooltipInPortal expects coordinates to be relative to containerRef
                          // localPoint returns coordinates relative to the nearest SVG, which
                          // is what containerRef is set to in this example.
                          const eventSvgCoords = localPoint(event);
                          const left = bar.x + bar.width / 2;
                          event?.currentTarget?.style.setProperty(
                            "opacity",
                            "0.8"
                          );
                          event?.currentTarget?.style.setProperty(
                            "cursor",
                            "pointer"
                          );
                          showTooltip({
                            tooltipData: bar,
                            tooltipTop: eventSvgCoords?.y,
                            tooltipLeft: left,
                          });
                        }}
                      />

                      {bar.bar[1] !== 0 &&
                        bar.bar.data[bar.key] !== 0 &&
                        bar.width > 10 && (
                          <Text
                            paintOrder={0}
                            fill="#FFF"
                            fontSize="clamp(0.5rem, 2vw, 0.75rem)"
                            x={bar.x + bar.width / 2}
                            y={bar.y + bar.height / 2}
                            dominantBaseline="middle"
                            textAnchor="middle"
                          >
                            {
                              {
                                number: formatToSimpleNumber(
                                  bar.bar.data[bar.key]
                                ),
                                currency: formatToSimpleCurrency(
                                  bar.bar.data[bar.key]
                                ),
                                percentage: formatPercentage(
                                  1,
                                  bar.bar.data[bar.key]
                                ),
                                simple: formatToSimpleNumber(
                                  bar.bar.data[bar.key]
                                ),
                              }[yUnit || "number"]
                            }
                          </Text>
                        )}
                    </Group>
                  ))}
                </React.Fragment>
              ))
            }
          </BarStackHorizontal>
        </Group>
      </svg>

      <div
        style={{
          position: "absolute",
          bottom: margin.bottom,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          fontSize: "clamp(0.8rem, 2vw, 1rem)",
          maxHeight: "4rem",
          overflow: "scroll",
        }}
      >
        <LegendOrdinal
          style={{
            flexWrap: "wrap",
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
          scale={colorScale}
          direction="row"
          labelMargin="0 1rem 0 0"
        />
      </div>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>{tooltipData.key}</strong>
          </div>
          {tooltipData.bar && tooltipData.bar.data && (
            <div>
              <div>
                {
                  {
                    number: formatNumber(tooltipData.bar.data[tooltipData.key]),
                    currency: formatToSimpleCurrency(
                      tooltipData.bar.data[tooltipData.key]
                    ),
                    percentage: formatPercentage(
                      1,
                      tooltipData.bar.data[tooltipData.key]
                    ),
                    simple: formatToSimpleNumber(
                      tooltipData.bar.data[tooltipData.key]
                    ),
                  }[yUnit || "number"]
                }
              </div>
            </div>
          )}
          {tooltipData.bar && tooltipData.bar.data && (
            <div>
              {isMonthly ? (
                <small>{formatMonth(getMonth(tooltipData.bar?.data))}</small>
              ) : (
                <small>{formatDate(getDate(tooltipData.bar?.data))}</small>
              )}
            </div>
          )}
        </TooltipInPortal>
      )}
    </div>
  );
}
