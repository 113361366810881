import { localPoint } from "@visx/event";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { LegendOrdinal } from "@visx/legend";
import { AnimatedAxis } from "@visx/react-spring";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { BarGroup, Line } from "@visx/shape";
import { SeriesPoint } from "@visx/shape/lib/types";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { format as d3Format } from "d3-format";
import { timeParse, timeFormat } from "d3-time-format";
import React from "react";
import {
  formattedCurrency,
  formattedNumber,
  colorRange,
} from "../../src/helpers";
import { theme } from "../../themes/base";
import { filterKeyFromObjects } from "../../utils";
import { compose } from "../../utils/compose";

type UnitType = "currency" | "percentage" | "number" | "simple";

type TooltipData = {
  bar: SeriesPoint<any>;
  color: string;
  height: number;
  index: number;
  key: string;
  width: number;
  x: number;
  y: number;
};

export type BarsProps = {
  data: any;
  events?: boolean;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  width: number;
  unit?: UnitType;
  xUnit?: UnitType;
  x0Unit?: UnitType;
  x1Unit?: UnitType;
  y0Unit?: UnitType;
  y1Unit?: UnitType;
};

// function scaleBandInvert(scale: any) {
//   var domain = scale.domain();
//   var paddingOuter = scale(domain[0]);
//   var eachBand = scale.step();
//   return function (value: any) {
//     var index = Math.floor((value - paddingOuter) / eachBand);
//     return domain[Math.max(0, Math.min(index, domain.length - 1))];
//   };
// }

export function Bars({
  data,
  /* @ts-expect-error */
  events = false,
  height,
  margin = { top: 32, right: 40, bottom: 32, left: 40 },
  width,
  unit,
  /* @ts-expect-error */
  xUnit,
  /* @ts-expect-error */
  x0Unit,
  /* @ts-expect-error */
  x1Unit,
  /* @ts-expect-error */
  y0Unit,
  /* @ts-expect-error */
  y1Unit,
}: BarsProps) {
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: "#EEE",
    color: "#000",
  };

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom - 96;

  let tooltipTimeout: number;

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  // const handleTooltip = (event: any) => {
  //   const { x, y } = localPoint(event) || { x: 0, y: 0 };
  //   const x0 = scaleBandInvert(monthScale)(x - margin.left); // get Date from the scale
  //   const point = data.find((z: any) => z.month === x0); // bisectDate(data, x0, 1); // get index of this date from the array
  //   showTooltip({
  //     tooltipData: {
  //       bar: { data: point },
  //       color: "",
  //       height: 0,
  //       index: 0,
  //       key: point.line0,
  //       width: 0,
  //       x,
  //       y,
  //     },
  //     tooltipLeft: x,
  //     tooltipTop: y,
  //   });
  // };

  const keys = Object.keys(data[0] || [])
    .filter((d) => d !== "date" && d !== "month")
    .sort() as any[];

  const isMonthly = Object.keys(data[0] || []).includes("month");

  const values = data.reduce((allTotals: any, currentDate: any) => {
    const totalCount = keys.reduce((dailyTotal, k) => {
      dailyTotal += Number(currentDate[k]);
      return dailyTotal;
    }, 0);
    allTotals.push(totalCount);
    return allTotals;
  }, [] as number[]);

  // accessors
  // const date = (d: any) => {
  //   if (!d.x) {
  //     return d;
  //   }
  //   return new Date(`${d.x}T00:00:00`);
  // };

  // const value = (d: any) => d.y;

  const filterX = (arr: any): any => filterKeyFromObjects("x", arr);
  const filterY = (arr: any): any => filterKeyFromObjects("y", arr);
  const filterY0 = (arr: any): any => filterKeyFromObjects("y0", arr);
  const filterY1 = (arr: any): any => filterKeyFromObjects("y1", arr);
  const filterX0 = (arr: any): any => filterKeyFromObjects("x0", arr);
  const filterX1 = (arr: any): any => filterKeyFromObjects("x1", arr);
  const filteredDataWithoutCoords = compose(
    filterX,
    filterX0,
    filterX1,
    filterY,
    filterY0,
    filterY1
  );
  const dataWithoutCoords = filteredDataWithoutCoords(data);

  const parseMonth = timeParse("%Y-%m");
  const parseDate = timeParse("%Y-%m-%d");
  const format = timeFormat("%x");
  // const formatToCurrency = d3Format("$,");
  // const formatToPercent = d3Format(",.1%");
  const formatToSimpleCurrency = d3Format("$,.3~s");
  const formatToSimpleNumber = d3Format(".3~s");

  const formatDate = (d: any) => format(parseDate(d) as Date);
  const formatMonth = (d: any) => format(parseMonth(d) as Date);
  // const formatCurrency = (v: number) => formatToCurrency(v);
  const formatSimpleCurrency = (v: number) => formatToSimpleCurrency(v);
  // const formatPercent = (v: number) => formatToPercent(v);

  // accessors
  const getDate = (d: any) => d.date;
  const getMonth = (d: any) => d.month;

  // scales
  const dateScale = scaleBand<string>({
    domain: data.map(getDate),
    padding: 0,
    paddingInner: 0.2,
    paddingOuter: 0.3,
    round: true,
  });

  const monthScale = scaleBand<string>({
    domain: data.map(getMonth),
    padding: 0,
    paddingInner: 0.2,
    paddingOuter: 0.1,
    round: true,
  });

  const maxDomainValueScale = Math.max(...values, Math.max(...values));

  const valueScale = scaleLinear<number>({
    domain: [0, maxDomainValueScale === 0 ? 1000 : maxDomainValueScale],
    nice: true,
  });

  const colorScale = scaleOrdinal<any, string>({
    domain: keys,
    range: colorRange,
  });

  const keyScale = scaleBand<string>({
    domain: keys,
    padding: 0,
  });

  // const xScale = scaleBand({
  //   range: [0, xMax],
  //   // round: [0, xMax],
  //   domain: [0, Math.max(...values)],
  //   padding: 0.4,
  // });

  // const yScale = scaleLinear({
  //   range: [0, yMax],
  //   domain: [0, Math.max(...values)],
  // });

  dateScale.rangeRound([0, xMax]);
  monthScale.rangeRound([0, xMax]);
  valueScale.range([yMax, 0]);
  keyScale.rangeRound([
    0,
    isMonthly ? monthScale.bandwidth() : dateScale.bandwidth(),
  ]);

  if (!data) {
    return null;
  }

  if (width < 10) return null;

  return (
    <div style={{ position: "relative", marginTop: "1rem" }}>
      <svg ref={containerRef} width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <Grid
            xScale={isMonthly ? monthScale : dateScale}
            yScale={valueScale}
            width={xMax}
            height={yMax}
            stroke={theme.colors.primary}
            strokeOpacity={0.1}
            numTicksColumns={data.length - 1}
            numTicksRows={12}
          />
          <AnimatedAxis
            // 16px (base font size) * 32rem = width
            hideAxisLine
            hideTicks
            numTicks={12}
            orientation="left"
            left={-24}
            scale={valueScale}
            stroke={theme.colors.primary}
            strokeWidth={1}
            // @ts-ignore
            tickFormat={formatToSimpleNumber}
            {...(unit === "currency" && { tickFormat: formatSimpleCurrency })}
            // {...(xUnit === "currency" && { tickFormat: formatSimpleCurrency })}
            // {...(xUnit === "percentage" && {
            //   tickFormat: formatPercentage.bind(null, 2),
            // })}
            // {...(yUnit === "currency" && {
            //   tickFormat: formatSimpleCurrency,
            // })}
            // {...(yUnit === "percentage" && {
            //   tickFormat: formatPercentage.bind(null, 2),
            // })}
            tickLabelProps={() => ({
              fill: theme.colors.primary,
              fontSize: "clamp(0.5rem, 2vw, 0.6rem)",
            })}
          />

          <AnimatedAxis
            top={yMax}
            scale={isMonthly ? monthScale : dateScale}
            stroke={theme.colors.primary}
            // 16px (base font size) * 32rem = width
            numTicks={width < 800 ? 12 : 45}
            tickFormat={isMonthly ? formatMonth : formatDate}
            tickStroke={theme.colors.primary}
            tickLabelProps={() => ({
              fill: theme.colors.primary,
              fontSize: "clamp(0.5rem, 2vw, 0.6rem)",
              textAnchor: "middle",
            })}
            orientation="bottom"
          >
            {(props) => {
              const tickLabelSize = 10;
              const tickRotate = -45;
              const tickColor = theme.colors.primary;
              const axisCenter =
                (props.axisToPoint.x - props.axisFromPoint.x) / 2;
              return (
                <g>
                  {props.ticks.map((tick, i) => {
                    const tickX = tick.to.x;
                    const tickY = tick.to.y + tickLabelSize;
                    return (
                      <Group
                        key={`vx-tick-${tick.value}-${i}`}
                        className={"vx-axis-tick"}
                      >
                        <Line
                          from={tick.from}
                          to={tick.to}
                          stroke={theme.colors.primary}
                        />
                        <text
                          transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                          fontSize={tickLabelSize}
                          textAnchor="end"
                          fill={tickColor}
                        >
                          {tick.formattedValue}
                        </text>
                      </Group>
                    );
                  })}
                  <text
                    textAnchor="middle"
                    transform={`translate(${axisCenter}, 50)`}
                    fontSize="clamp(0.5rem, 2vw, 0.6rem)"
                  >
                    {props.label}
                  </text>
                </g>
              );
            }}
          </AnimatedAxis>

          <BarGroup
            color={colorScale}
            data={dataWithoutCoords}
            height={yMax}
            keys={keys}
            x0={isMonthly ? getMonth : getDate}
            x0Scale={isMonthly ? monthScale : dateScale}
            x1Scale={keyScale}
            yScale={valueScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                >
                  {barGroup.bars.map((bar: any) => (
                    <Group
                      key={`bar-group-fragment-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                    >
                      <rect
                        key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                        x={bar.x}
                        y={bar.y}
                        width={bar.width}
                        height={bar.height}
                        fill={bar.color}
                        onMouseLeave={(event) => {
                          if (!window) return;
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                          event?.currentTarget?.style.setProperty(
                            "opacity",
                            "1"
                          );
                          event?.currentTarget?.style.setProperty(
                            "cursor",
                            "default"
                          );
                        }}
                        onMouseEnter={(event) => {
                          if (!window) return;
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                          event?.currentTarget.style.setProperty(
                            "opacity",
                            "1"
                          );
                          event?.currentTarget.style.setProperty(
                            "cursor",
                            "default"
                          );
                        }}
                        onMouseMove={(event) => {
                          if (!event) return;
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          // TooltipInPortal expects coordinates to be relative to containerRef
                          // localPoint returns coordinates relative to the nearest SVG, which
                          // is what containerRef is set to in this example.
                          const eventSvgCoords = localPoint(event);
                          event?.currentTarget?.style.setProperty(
                            "opacity",
                            "0.8"
                          );
                          event?.currentTarget?.style.setProperty(
                            "cursor",
                            "pointer"
                          );
                          showTooltip({
                            tooltipData: bar,
                            tooltipTop: eventSvgCoords?.y,
                            tooltipLeft: eventSvgCoords?.x,
                          });
                        }}
                      />
                    </Group>
                  ))}
                </Group>
              ))
            }
          </BarGroup>
        </Group>
      </svg>

      <div
        style={{
          position: "absolute",
          bottom: margin.bottom - (keys.length > 4 ? 15 : 0),
          width: "100%",
          display: "flex",
          justifyContent: "center",
          fontSize: "clamp(0.8rem, 2vw, 1rem)",
        }}
      >
        <LegendOrdinal
          style={{
            flexWrap: "wrap",
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
          scale={colorScale}
          direction="row"
          labelMargin="0 1rem 0 0"
        />
      </div>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>
            <div>
              {unit === "currency"
                ? /* @ts-expect-error */
                  formattedCurrency(tooltipData?.value /* @ts-expect-error */)
                : formattedNumber(tooltipData?.value)}
            </div>
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
}
