import { localPoint } from "@visx/event";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { LegendOrdinal } from "@visx/legend";
import { AnimatedAxis } from "@visx/react-spring";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Bar, Line } from "@visx/shape";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { format as d3Format } from "d3-format";
import React from "react";
import { colorRange } from "../../src/helpers";
import { theme } from "../../themes/base";
import { filterKeyFromObjects, formatPercentage } from "../../utils";
import { compose } from "../../utils/compose";
import { ErrorWidget } from "../ErrorWidget";

type UnitType = "currency" | "percentage" | "number" | "simple";

type TooltipData = {
  xValue: number;
  yValue: string;
};

export type BarChartProps = {
  data: any;
  events?: boolean;
  height: number;
  hideBarLabels?: boolean;
  margin?: { top: number; right: number; bottom: number; left: number };
  numTicks?: number;
  unit?: UnitType;
  width: number;
  xUnit?: UnitType;
  yUnit?: UnitType;
};

// @see https://observablehq.com/@camdecoster/d3-tester
const formatToSimpleCurrency = d3Format("$,.3~s");
const formatToSimpleNumber = d3Format(".3~s");

const formatSimpleCurrency = (value: number) => formatToSimpleCurrency(value);

// accessors
const getX = (d: any) => d.xValue;
const getY = (d: any) => d.yValue;

export function BarChart({
  data = [],
  height,
  hideBarLabels = false,
  margin = { top: 32, right: 40, bottom: 32, left: 40 },
  width,
  unit,
  xUnit,
  yUnit,
  numTicks,
}: BarChartProps) {
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: "#EEE",
    color: "#000",
  };

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom - 96;

  let tooltipTimeout: number;

  const filterX = (arr: any): any => filterKeyFromObjects("x", arr);
  const filterY = (arr: any): any => filterKeyFromObjects("y", arr);
  const filterY0 = (arr: any): any => filterKeyFromObjects("y0", arr);
  const filterY1 = (arr: any): any => filterKeyFromObjects("y1", arr);
  const filterX0 = (arr: any): any => filterKeyFromObjects("x0", arr);
  const filterX1 = (arr: any): any => filterKeyFromObjects("x1", arr);
  const filteredDataWithoutCoords = compose(
    filterX,
    filterX0,
    filterX1,
    filterY,
    filterY0,
    filterY1
  );

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const keys = data.map((d: any) => d.yValue);
  keys.sort(isNaN(keys[0]) ? undefined : (a: number, b: number) => a - b);

  if (isNaN(keys[0])) {
    data.sort((a: any, b: any) => a.yValue.localeCompare(b.yValue));
  }
  const dataWithoutCoords = filteredDataWithoutCoords(data);
  const values = dataWithoutCoords.map((d: any) => Number(d.xValue));

  // scales
  const xScale = scaleBand<string>({
    range: [0, xMax],
    round: true,
    domain: data.map(getY),
    padding: 0.2,
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    round: true,
    domain: [0, Math.max(...data.map(getX))],
  });

  const valueScale = scaleLinear<number>({
    domain: [0, Math.max(...values)],
    nice: true,
  });

  const colorScale = scaleOrdinal<any, string>({
    domain: keys,
    range: colorRange,
  });

  valueScale.range([yMax, 0]);

  if (!data || data.length < 1) {
    return <ErrorWidget h100 />;
  }

  if (width < 10) return null;

  return (
    <div style={{ position: "relative", marginTop: "1.5rem" }}>
      <svg ref={containerRef} width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <Grid
            xScale={xScale}
            yScale={valueScale}
            width={xMax}
            height={yMax}
            stroke={theme.colors.primary}
            strokeOpacity={0.1}
          />
          <AnimatedAxis
            hideAxisLine
            hideTicks
            numTicks={numTicks}
            orientation="left"
            left={-24}
            scale={valueScale}
            stroke={theme.colors.primary}
            strokeWidth={1}
            // @ts-ignore
            tickFormat={formatToSimpleNumber}
            {...(unit === "currency" && { tickFormat: formatSimpleCurrency })}
            {...(xUnit === "currency" && {
              tickFormat: formatSimpleCurrency,
            })}
            {...(xUnit === "percentage" && {
              tickFormat: formatPercentage.bind(null, 2),
            })}
            {...(yUnit === "currency" && {
              tickFormat: formatSimpleCurrency,
            })}
            {...(yUnit === "percentage" && {
              tickFormat: formatPercentage.bind(null, 2),
            })}
            tickLabelProps={() => ({
              fill: theme.colors.primary,
              fontSize: "clamp(0.5rem, 2vw, 0.6rem)",
            })}
          />

          {!hideBarLabels && (
            <AnimatedAxis
              top={yMax}
              scale={xScale}
              stroke={theme.colors.primary}
              numTicks={width < 16 * 32 ? 8 : 12}
              tickStroke={theme.colors.primary}
              tickLabelProps={() => ({
                fill: theme.colors.primary,
                fontSize: "clamp(0.5rem, 2vw, 0.6rem)",
                textAnchor: "middle",
              })}
              orientation="bottom"
            >
              {(props) => {
                const tickLabelSize = 10;
                const tickRotate = -45;
                const tickColor = theme.colors.primary;
                const axisCenter =
                  (props.axisToPoint.x - props.axisFromPoint.x) / 2;
                return (
                  <Group>
                    {props.ticks.map((tick, i) => {
                      const tickX = tick.to.x;
                      const tickY = tick.to.y + tickLabelSize;

                      return (
                        <Group
                          key={`vx-tick-${tick.value}-${i}`}
                          className={"vx-axis-tick"}
                        >
                          <Line
                            from={tick.from}
                            to={tick.to}
                            stroke={theme.colors.primary}
                          />
                          <Text
                            transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                            fontSize={tickLabelSize}
                            textAnchor="end"
                            fill={tickColor}
                          >
                            {tick.value}
                          </Text>
                        </Group>
                      );
                    })}
                    <Text
                      textAnchor="middle"
                      transform={`translate(${axisCenter}, 50)`}
                      fontSize="clamp(0.5rem, 2vw, 0.6rem)"
                    >
                      {props.label}
                    </Text>
                  </Group>
                );
              }}
            </AnimatedAxis>
          )}

          {data.map((d: any, index: any) => {
            const colorPicker = () => {
              let counter = index;
              while (counter > colorRange.length - 1) {
                counter -= colorRange.length;
              }
              return counter;
            };

            const delinquency = getY(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getX(d)) ?? 0);
            const barX = xScale(delinquency) || 0;
            const barY = yMax - barHeight;
            return (
              <React.Fragment key={`bar-stacks-frag-${index}`}>
                <Bar
                  key={`bar-${index}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill="rgba(23, 233, 217, .5)"
                  color={colorRange[index]}
                />
                <Group key={`bar-group-frag-${index}`}>
                  <rect
                    key={`bar-group-${index}`}
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    fill={colorRange[colorPicker()]}
                    onMouseLeave={(event) => {
                      if (!window) return;
                      tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 300);
                      event?.currentTarget?.style.setProperty("opacity", "1");
                      event?.currentTarget?.style.setProperty(
                        "cursor",
                        "default"
                      );
                    }}
                    onMouseEnter={(event) => {
                      if (!window) return;
                      tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 300);
                      event?.currentTarget.style.setProperty("opacity", "1");
                      event?.currentTarget.style.setProperty(
                        "cursor",
                        "pointer"
                      );
                    }}
                    onMouseMove={(event) => {
                      if (!event) return;
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      const eventSvgCoords = localPoint(event);
                      event?.currentTarget?.style.setProperty("opacity", "0.8");
                      event?.currentTarget?.style.setProperty(
                        "cursor",
                        "pointer"
                      );
                      showTooltip({
                        tooltipData: d,
                        tooltipTop: eventSvgCoords?.y,
                        tooltipLeft: eventSvgCoords?.x,
                      });
                    }}
                  />
                  <Text
                    paintOrder={0}
                    fill={theme.colors.primary}
                    fontSize="clamp(0.5rem, 2vw, 0.75rem)"
                    x={barX + barWidth / 2}
                    y={barY - 10}
                    dominantBaseline="middle"
                    textAnchor="middle"
                  >
                    {
                      {
                        number: formatToSimpleNumber(getX(d)),
                        currency: formatToSimpleCurrency(1),
                        percentage: formatPercentage(1, getX(d)),
                        simple: formatToSimpleNumber(1),
                      }[yUnit || "number"]
                    }
                  </Text>
                </Group>
              </React.Fragment>
            );
          })}
        </Group>
      </svg>

      <div
        style={{
          position: "absolute",
          bottom: margin.bottom,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          fontSize: "clamp(0.8rem, 2vw, 1rem)",
          maxHeight: "4rem",
          overflow: "scroll",
        }}
      >
        <LegendOrdinal
          style={{
            flexWrap: "wrap",
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
          scale={colorScale}
          direction="row"
          labelMargin="0 1rem 0 0"
        />
      </div>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div style={{ color: colorScale(tooltipData?.yValue) }}>
            <strong>{tooltipData.yValue}</strong>
          </div>
          <div>
            <div>{formatToSimpleNumber(tooltipData?.xValue)}</div>
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
}
